<template>
	<section>
		<div v-if="cargando.orden" class="card">
			<div class="card-body">
				<div class="loading loading-lg"></div>
			</div>
		</div>
		<div v-else class="card">
			<div class="card-header">
				<div class="card-title h4">Orden: {{ orden.id }}</div>
			</div>
			<div class="card-body">
				<div class="columns">
					<div class="column col-12">
						<div class="divider text-center" data-content="Información del Cliente"></div>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Nombre del Cliente</strong>
						<p class="text-bold">
							<router-link :to="{ name: 'Cliente', params: { id: orden.cliente_id } } ">{{ orden.cliente_nombre }}</router-link>
						</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Teléfono del Cliente</strong>
						<p>{{ orden.cliente_telefono }}</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Dirección del Cliente</strong>
						<p>{{ orden.cliente_direccion }}</p>
					</div>
					<div class="column col-12">
						<div class="divider text-center" data-content="Información de la Orden"></div>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Creado el</strong>
						<p>{{ orden.creado }}</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Estado</strong>
						<p>{{ orden.estado }}</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Asignado por</strong>
						<p>{{ orden.supervisor_nombre }}</p>
					</div>
				</div>
				<button v-if="orden.estado_id == 1" @click="comenzar()" :class="cargando.btnComenzar?'loading':''" class="btn btn-lg btn-primary">Comenzar</button>
				<div v-else class="columns">
					<div class="column col-12">
						<strong>Actividades</strong>
						<div v-if="actividad.cargando">
							<div class="loading loading-lg"></div>
						</div>
						<div v-else class="timeline mt-2">
							<div v-for="(i, k) in actividad" :key="k" class="timeline-item">
								<div class="timeline-left">
									<span class="timeline-icon"></span>
								</div>
								<div class="timeline-content">
									<details class="accordion">
										<summary class="accordion-header c-hand">
											{{ i.servicio }} <i class="icon icon-caret"></i>
										</summary>
										<div class="accordion-body">
											<blockquote class="text-tiny">
												<ul>
													<li>Creado el {{ i.creado }}</li>
													<li>Materiales: {{ i.materiales }}</li>
													<li v-if="i.fotos">
														<router-link :to="{name:'Fotos', params:{id:i.id}}">Fotos: {{ i.fotos }}</router-link>
													</li>
													<li>Observaciones: {{ i.observaciones }}</li>
												</ul>
											</blockquote>
										</div>
									</details>
								</div>
							</div>
						</div>
						<button @click="actividadModal()" class="btn btn-sm">
							<i class="icon icon-plus"></i> Agregar Actividad
						</button>
						<p><!-- a --></p>
					</div>
					<div class="column col-12">
						<strong>Firma del Cliente</strong>
						<div class="my-2">
							<div v-if="orden.firma == 0">
								<button @click="firmaModal()" class="btn my-2">
									<i class="icon icon-plus"></i> Agregar Firma
								</button>
							</div>
							<div v-else>
								<a :href="imgFirma" target="_blank">
									<img :src="imgFirma" class="firma my-2" alt="Firma">
								</a>
								<button @click="borrarFirma()" :class="cargando.btnFirmaBorrar?'loading':''" class="btn btn-error btn-sm my-2">
									<i class="icon icon-delete"></i> Borrar Firma
								</button>
							</div>
						</div>
					</div>
					<div class="column col-12">
						<div class="divider"></div>
					</div>
					<div class="column col-6">
						<button @click="cambiarEstado(4)" :class="cargando.btnCambiarEstado?'loading':''" class="btn btn-error btn-block">
							<i class="icon icon-stop"></i> Orden con Inicidencias
						</button>
					</div>
					<div class="column col-6">
						<button @click="cambiarEstado(3)" :class="cargando.btnCambiarEstado?'loading':''" class="btn btn-success btn-block">
							<i class="icon icon-check"></i> Finalizar Orden
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal" :class="modalActividad ? 'active' : ''">
			<a @click.prevent="actividadModal()" href="#" class="modal-overlay" aria-label="Close"></a>
			<div class="modal-container">
				<div class="modal-header">
					<a @click.prevent="actividadModal()" href="#" class="btn btn-clear float-right" aria-label="Close"></a>
					<div class="modal-title h5">Nueva Actividad</div>
				</div>
				<div class="modal-body">
					<form class="content">
						<div class="form-group">
							<label class="form-label text-bold">Servicio</label>
							<select class="form-select" v-model="actividadForm.servicio">
								<option v-for="(i, k) in servicios" :key="k" :value="i.id">{{ i.nombre }}</option>
							</select>
						</div>
						<div class="form-group">
							<label class="form-label text-bold">Materiales</label>
							<textarea v-model="actividadForm.materiales" class="form-input" rows="3"></textarea>
						</div>
						<div class="form-group">
							<label class="form-label text-bold">Fotos</label>
							<input class="form-input" type="file" accept="image/*" ref="actividadFotos" multiple>
						</div>
						<div class="form-group">
							<label class="form-label text-bold">Observaciones</label>
							<textarea v-model="actividadForm.observaciones" class="form-input" rows="3"></textarea>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button @click="enviarActividad()" :class="cargando.formActividad ? 'loading' : ''" class="btn btn-primary">Agregar Actividad</button>
				</div>
			</div>
		</div>
		<div class="modal" :class="modalFirma ? 'active' : ''">
			<a @click.prevent="firmaModal()" href="#" class="modal-overlay" aria-label="Close"></a>
			<div class="modal-container">
				<div class="modal-header">
					<a @click.prevent="firmaModal()" href="#" class="btn btn-clear float-right" aria-label="Close"></a>
					<div class="modal-title h5">Firma del Cliente</div>
				</div>
				<div class="modal-body">
					<div class="content">
						<VueSignaturePad height="10rem" :customStyle="{ border:'solid 2px black', cursor:'crosshair'}" ref="signaturePad" />
					</div>
				</div>
				<div class="modal-footer columns">
					<div class="column col-6">
						<button @click="limpiarFirma()" class="btn btn-error btn-block">Limpiar Firma</button>
					</div>
					<div class="column col-6">
						<button @click="enviarFirma()" :class="cargando.btnFirmaEnviar?'loading':''" class="btn btn-success btn-block">Guardar Firma</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { VueSignaturePad } from 'vue-signature-pad';
	export default {
		name: 'TN_Orden',
		components: { VueSignaturePad },
		data() {
			return {
				cargando: {
					orden            : false,
					actividad        : false,
					servicios        : false,
					btnComenzar      : false,
					formActividad    : false,
					btnFirmaEnviar   : false,
					btnFirmaBorrar   : false,
					btnCambiarEstado : false,
				},
				modalActividad : false,
				modalFirma     : false,
				orden: [],
				actividad: [],
				servicios: [],
				actividadForm: {
					orden: 0,
					servicio: 1,
					materiales: '',
					observaciones: ''
				},
				firma: '',
				imgFirma: ''
			};
		},
		async mounted() {
			document.title = 'Cargando...';
			this.infoUsuario();
			this.cargarOrden();
			this.cargarActividad();
			this.cargarServicios();
		},
		methods: {
			infoUsuario() {
				if(Object.keys(this.$store.state.user).length === 0) {
					this.$store.state.api
						.get('usuario')
						.then(res => this.$store.commit('setUser', res.data))
						.catch(err => alert(err.response.data.mensaje));
				}
			},
			async cargarOrden() {
				this.cargando.orden = true;
				await this.$store.state.api
					.get('ordenes/tn_id/' + parseInt(this.$route.params.id))
					.then(res => {
						this.orden = res.data;
						this.actividadForm.orden = res.data.id;
						document.title = `Orden de Servicio #${res.data.id}`;
						this.imgFirma = `https://wsm.wuipi.net/api/firmas/${res.data.id}.png`;
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando.orden = false);
			},
			async cargarActividad() {
				this.cargando.actividad = true;
				await this.$store.state.api
					.get('ordenes/actividad/' + parseInt(this.$route.params.id))
					.then(res => this.actividad = res.data)
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando.actividad = false);
			},
			cargarServicios() {
				this.cargando.servicios = true;
				this.$store.state.api
					.get('servicios/listado')
					.then(res => this.servicios = res.data)
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando.servicios = false);
			},
			async cargarFotos(id) {
				let form = new FormData();
					form.append('aid', id);
				for(let foto of this.$refs.actividadFotos.files) {
					form.append('fotos[]', foto);
				}
				await this.$store.state.api
					.post('ordenes/cargar_fotos', form, {headers: {'Content-Type': 'multipart/form-data'}});
			},
			comenzar() {
				this.cargando.btnComenzar = true;
				this.$store.state.api
					.get('ordenes/tn_comenzar/' + parseInt(this.$route.params.id))
					.then(() => this.cargarOrden())
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando.btnComenzar = false);
			},
			actividadModal() {
				this.modalActividad = !this.modalActividad;
			},
			enviarActividad() {
				this.cargando.formActividad = true;
				this.$store.state.api
					.post('ordenes/tn_nueva_actividad', this.actividadForm)
					.then(async res => {
						await this.cargarFotos(res.data.id);
						await this.cargarActividad();
						this.modalActividad = false;
						this.actividadForm.servicio = 1;
						this.actividadForm.materiales = '';
						this.actividadForm.observaciones = '';
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando.formActividad = false);
			},
			firmaModal() {
				this.modalFirma = !this.modalFirma;
			},
			enviarFirma() {
				const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
				this.firma = isEmpty ? '' : data;
				this.cargando.btnFirmaEnviar = true;
				this.$store.state.api
					.post('firma/enviar/' + parseInt(this.$route.params.id), {'firma': this.firma})
					.then(async () => {
						await this.cargarOrden();
						this.modalFirma = false;
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando.btnFirmaEnviar = false);
			},
			borrarFirma() {
				this.cargando.btnFirmaBorrar = true;
				this.$store.state.api
					.get('firma/borrar/' + parseInt(this.$route.params.id))
					.then(async () => {
						await this.cargarOrden();
						this.modalFirma = false;
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando.btnFirmaBorrar = false);
			},
			limpiarFirma() {
				this.$refs.signaturePad.clearSignature();
				this.firma = '';
			},
			cambiarEstado(estado) {
				this.cargando.btnCambiarEstado = true;
				this.$store.state.api
					.get(`ordenes/estado/${this.orden.id}/${estado}`)
					.then(async () => await this.cargarOrden())
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando.btnCambiarEstado = false);
			},
		},
		watch: {
			modalFirma() {
				this.$nextTick(() => this.$refs.signaturePad.resizeCanvas());
			}
		}
	}
</script>

<style scoped>
	.divider {
		border-top: .05rem solid #b2b5bd;
	}
	.divider-vert[data-content]::after,
	.divider[data-content]::after {
		color: #3b4351!important;
	}
	.timeline .timeline-item:last-child::before {
		background: none;
	}
	.accordion .accordion-header {
		padding: 0;
	}
	.accordion input:checked~.accordion-header>.icon:first-child,
	.accordion[open] .accordion-header>.icon:first-child {
		transform: none;
	}
	details[open] summary ~ * {
		animation: sweep .25s ease-in-out;
	}
	@keyframes sweep {
		0%    {opacity: 0; margin-top: -15px}
		100%  {opacity: 1; margin-top: 0px}
	}
	blockquote {
		margin: 0;
		border-left: .1rem solid #e9ebf0;
	}
	ul {
		margin: 0;
	}
</style>
